import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby"
import Head from "../../head";
import Layout from "../../template/Layout"
import { BlogPaginator } from "../../components/blog/common/paginator";
import Articles from "../../components/blog/Articles";
import { WPFrontMatter } from "../../types/WPFrontMatter";
import { OWNEDMEDIA_WORDPRESS_URL } from "../../AppConst";
import { RedirectToErrorPage } from "../../utlis/404Util";

const fetchCategoryPosts = async (category, page, setPosts, setTotalPages, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/categories?slug=${category}`)
  .then(response => {return response.json()})
  .then(categoryData => {
    if(!categoryData.length) return RedirectToErrorPage(window);

    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&categories=${categoryData[0].id}&per_page=12&page=${page}`)
    .then(response => {
      setTotalPages(Number(response.headers.get("X-WP-TotalPages")));
      return response.json();
    })
    .then(postsData => {
      if(!postsData.length) return RedirectToErrorPage(window);
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          description: "",
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

/**
 *@ Element & Styles
 */
const baseBreadcrumbsData: { text: string; path: string }[] = [
  { text: 'BLOG', path: 'blog' },
]

type Props = {
  category: string
  pageNum: string
}

export const CategoryBlogs:React.VFC<Props> = ({category, pageNum}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [posts, setPosts] = useState<WPFrontMatter[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  const pageNumber = Number(pageNum)

  useEffect(() => {
    fetchCategoryPosts(category, pageNumber, setPosts, setTotalPages, setIsLoading, setHasError);
  }, [pageNum])

  const data = useStaticQuery(graphql`
    query {
        site {
        siteMetadata {
            member {
            name
            filename
            pathname
            }
        }
        }
        allSite {
        edges {
            node {
            siteMetadata {
                blogItems {
                item
                slug
                }
            }
            }
        }
        }
    }
  `)

  const breadcrumbsData = [
    ...baseBreadcrumbsData,
    ...[{ text: category.toUpperCase(), path: category }]
  ]

  const categoryName = () => {
    let meta = data.allSite.edges[0].node.siteMetadata
    for(let i = 0; i < meta.blogItems.length; i++){
      if (category == meta.blogItems[i].slug){
        return meta.blogItems[i].item
      }
    }
  }

  const title = categoryName() + "の記事一覧 | ネクストライブ株式会社"
  const description = "ブログの" + categoryName() + "の記事一覧ページです。テクノロジー、クリエイティブ、プログラミング、ビジネス、ワークスタイル、ライフスタイル、エンタメ、お知らせのブログ記事の一覧を掲載しています。"
  return (
    <Layout sidebar={true} breadcrumbsData={breadcrumbsData}>
      <Head
        title={title}
        description={description}
      />
      <Articles pageTitle={`${categoryName()}の記事一覧`} posts={posts} members={data.site.siteMetadata.member} isLoading={isLoading} hasError={hasError} />
      <BlogPaginator
        basePath={`/${category}`}
        prev={pageNumber > 2 ? `/${category}/${pageNumber - 1}` : `/${category}`}
        next={pageNumber < totalPages ? `/${category}/` + (pageNumber + 1) : ''}
        numberOfPages={totalPages}
        current={pageNumber}
      />
    </Layout>
  )
}

export default CategoryBlogs