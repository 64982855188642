import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

/**
 *@ Materials
 */
import { WPFrontMatter } from "../../types/WPFrontMatter"
import {
  RESPONSIVE_STYLES,
  BlogCategoriesJa,
  BlogCategoryColors,
} from "../../AppConst"
import { formateDate } from "../../utlis/formatDate"

/**
 *@ Elements & Style
 */
const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 240px;
  text-decoration: none;
  color: #000;

  &:hover {
    opacity: .7;
    h3 {
      text-decoration: underline;
    }
  }

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: unset;
    width: 100%;
    padding-bottom: 1.6rem;
    margin-bottom: 0;
    gap: 1.6rem;
    border-bottom: 1px solid #222;
    order: 1;
  }
`
const ImageWrapper = styled.div`
  width: 240px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 108px;
  }
  & > img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  gap: 0.8rem;
  box-sizing: border-box;
  background-color: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: calc(100% - 124px); // gap分含む
    padding: 0;
    background-color: transparent;
    gap: 0.4rem;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  }
`

const Category = styled.span<{ categoryColor: string }>`
  position: relative;
  padding-left: 1.6rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
  &::before {
    content: '';
    width: max(1em, 12px);
    height: max(1em, 12px);
    background-color: ${props => props.categoryColor};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`

const Date = styled.span`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7A7A7A;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
`

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: 0.05em;
  text-align: left;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1;
`

const Writer = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
  color: #222222;
`

/**
 *@ Types
 */
type State = {
  frontMatter: WPFrontMatter
  members: any
}
type Props = State

/**
 *@ ReactComponents
 */
const WPArticleListCard: React.FC<Props> = ({ frontMatter, members }: Props) => {
  const writer: { name: string, filename: string } = members.find((m) => m.name == frontMatter.author);

  return (
    <StyledLink to={"/" + frontMatter.slug}>
      <ImageWrapper>
        <img src={frontMatter.thumbnail} className='thumbnail' />
      </ImageWrapper>
      <ContentWrapper>
        <Date>{formateDate(frontMatter.date)}</Date>
        <Category categoryColor={BlogCategoryColors[frontMatter.category]}>{BlogCategoriesJa[frontMatter.category]}</Category>
        <Title>{frontMatter.title}</Title>
        <Footer>
          <Avatar src={`/images/${writer.filename}`} />
          <Writer>{writer.name}</Writer>
        </Footer>
      </ContentWrapper>
    </StyledLink>
  )
}

export default WPArticleListCard
