import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NUM_OF_DISPLAY_ITEM = 5 // ドット無しで表示するページの最大数
const DOT = 3 // 表示するドットの数
const STANDARD_SIDE_PAGES = 1 // ドットありの場合に、カレントページのサイドに表示できるページの最大数

type Props = {
  className?: string
} & ContainerProps
type ContainerProps = {
  current: number
  items: { path: string }[]
}

const Component: React.FC<Props> = React.memo(
  ({ className, items, current }) => {
    const currentIndex = current - 1;
    const lastIndex = items.length > 1 ? items.length - 1 : 0;

    const setItemClass = (count :number) => {
      return "item" + `${count === current ? ` active` : ``}`;
    }

    const PageLink: React.VFC<{i: number, item: any}> = ({i, item}) => {
      return (
        <Link
          key={i}
          to={item.path}
          className={setItemClass(i + 1)}
        >
          {i + 1}
        </Link>
      )
    }

    const FirstPageLink: React.VFC<{item: any}> = ({item}) => {
      return (
        <PageLink i={0} item={item} />
      )
    }

    const LastPageLink: React.VFC<{item: any}> = ({item}) => {
      if (items.length <= 1) return null;

      return <PageLink i={lastIndex} item={item} />
    }

    const Dot: React.VFC<{dotPosition: string}> = ({dotPosition}) => {
      return (
        <>
          {
            [...Array(DOT)].map((_, i) => <div key={i} className={`dot dot-${dotPosition}`}></div>)
          }
        </>
      )
    }

    const FirstDot: React.VFC = () => {
      if (currentIndex - STANDARD_SIDE_PAGES <= STANDARD_SIDE_PAGES)
        return null
      return <Dot dotPosition={"first"} />;
    }

    const LastDot: React.VFC = () => {
      if (lastIndex - 1 - currentIndex <= STANDARD_SIDE_PAGES)
        return null;
      return <Dot dotPosition={"last"} />
    }

    const StandardPageLink: React.VFC<{i: number, item: any}> = ({i, item}) => {
      // 最初と最後のリンクは作成しない
      if (i === 0 || i === lastIndex) return null;
      // リンクではなくドット表示するため条件
      if(i < currentIndex - STANDARD_SIDE_PAGES || i > currentIndex + STANDARD_SIDE_PAGES) return null;

      return <PageLink i={i} item={item} />
    }

    return (
      <div className={className}>
        <FirstPageLink item={items[0]} />
        <FirstDot />
        {
          items.map((item, i) => (
            <StandardPageLink item={item} i={i}/>
          ))
        }
        <LastDot />
        <LastPageLink item={items[lastIndex]} />
      </div>
    )
  }
)

const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  margin: 0 16px;

  .dot-first {
    &:nth-child(4) {
      margin-right: 16px !important;
    }
  }

  .dot-last {
    &:last-of-type {
      margin-right: 16px !important;
    }
  }

  & .item {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    color: #222;
    background-color: #efefef;
    text-decoration: none;
    margin-right: 16px;

    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  & .item:last-child {
    margin-right: 0;
  }

  & .dot {
    background-color: #222;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    margin-right: 5px;
  }

  & .active {
    background-color: #222;
    color: #fff;
  }
`

const Container: React.FC<ContainerProps> = React.memo(({ items, current }) => {
  return <StyledComponent items={items} current={current} />
})

export { Container as PaginatorList }
