import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { PaginatorList } from "./paginatorList"

import { RESPONSIVE_STYLES } from '../../../../AppConst'

type Props = {
  className?: string
  prev: string
  next: string
  items: { path: string }[]
  current: number
}

type ContainerProps = {
  prev: string
  next: string
  numberOfPages: number
  basePath: string
  current: number
  query?: string
}

const Component: React.FC<Props> = React.memo(function Component({
  className,
  prev,
  next,
  items,
  current,
}) {
  return (
    <div className={className}>
      <Link className='triangle-right' to={prev} />
      <div className="paginator-list">
        <PaginatorList items={items} current={current} />
      </div>
      <Link className="triangle-left" to={next} />
    </div>
  )
})

const StyledComponent = styled(Component)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}) {
    margin-top: 24px;
  }
  & .paginator-list {
    display: flex;
    align-items: center;
  }
  & .triangle-left {
      border-left: 12.8px solid #222;
      border-top: 7.4px solid transparent;
      border-bottom: 7.4px solid transparent;
      display: block;
      height: 0;
      width: 0;
      @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}) {
        border-left: 8px solid #222;
        border-top: 4.62px solid transparent;
        border-bottom: 4.62px solid transparent;
      }
  }
  & .triangle-right {
      display: block;
      border-right: 12.8px solid #222;
      border-top: 7.4px solid transparent;
      border-bottom: 7.4px solid transparent;
      height: 0;
      width: 0;
      @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}) {
        border-left: 8px solid #222;
        border-top: 4.62px solid transparent;
        border-bottom: 4.62px solid transparent;
      }
  }
  & .paginator-btn:last-child {
    transform: rotate(-135deg);
  }
`

const createPageLinks = (index, basePath, query) => {
  if(query) {
    return index + 1 > 1 ? `${basePath}?query=${query}&page=${index + 1}` : `${basePath}?query=${query}`
  }
  return index + 1 > 1 ? `${basePath}/${index + 1}` : basePath;
}

const Container: React.FC<ContainerProps> = React.memo(
  ({ prev, next, numberOfPages, basePath, current, query }) => {
    const items = [...Array(numberOfPages)].map((_, i) => ({
      path: createPageLinks(i, basePath, query)
    }))

    // 1ページしか存在しない場合はページャを非表示
    if (items.length <= 1) return null;

    return (
      <StyledComponent
        prev={prev}
        next={next}
        items={items}
        current={current}
      />
    )
  }
)

export { Container as BlogPaginator }
