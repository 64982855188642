import React, { useEffect } from "react"
import styled from "styled-components"

/**
 *@ Materials
 */
import { RESPONSIVE_STYLES } from "../../AppConst"
import CommonTitleJaOnly from "../common/CommonTitleJaOnly"
import ArticleListCard from "./ArticleListCard"
import { WPFrontMatter } from "../../types/WPFrontMatter"
import { LoadingAPI } from "../common/LoadingAPI"
import { ConnectionFailedText } from "../common/ConnectionFailedText"

/**
 *@ Elements & Style
 */
 const TitleWrapper = styled.div`
 margin-bottom: 40px;
 @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
   margin-bottom: 24px;
 }
`

const BlogsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`

/**
 *@ Types
 */
type State = {
  pageTitle: string
  posts: WPFrontMatter[] | null
  members: any
  count?: number
  isLoading?: boolean
  hasError?: boolean
}
type Props = State

/**
 *@ ReactComponents
 */
const Articles: React.FC<Props> = ({ pageTitle, posts, members, count, isLoading = false, hasError = false  }) => {
  return (
    <>
      <TitleWrapper>
        <CommonTitleJaOnly value={pageTitle} count={count} />
      </TitleWrapper>
      { hasError ? <ConnectionFailedText /> :
          isLoading ? <LoadingAPI /> :
            <BlogsWrapper>
              {posts?.map((post, i) => {
                return (
                  <ArticleListCard
                    key={i}
                    frontMatter={post}
                    members={members}
                  />
                )
              })}
            </BlogsWrapper>
      }
    </>
  )
}

export default Articles
